<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs}">
      <v-text-field
        v-model="dtString"
        placeholder="Emição"
        dense
        v-bind="attrs"
        v-on="on"
        @input="testeDate(dtString)"
      >
          <template v-slot:append>
            <v-icon v-on="on">
              event
            </v-icon>
          </template>
      </v-text-field>
    </template>

    <v-date-picker
      v-model="date"
      @input="closeModalDatePicker(date)"
      locale="pt-br"
      no-title
      :min="min"
    >
      <v-btn color="primary" @click="now()">Hoje</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment'

export default {
  name: "ModalDatePicker",
  data: () => ({
    menu: false,
    date: moment().format('YYYY-MM-DD'),
    dtString: moment().format('DD/MM/YYYY')
  }),

  props: {
    min: {
      type: String,
      default: moment().format('YYYY-MM-DD')
    }
  },

  methods: {
    testeDate (dtString) {
      const testeDate =  moment(dtString, 'DD/MM/YYYY', true)
      if (testeDate.isValid()) {
        this.date = moment(dtString, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
    },

    closeModalDatePicker (date) {
      this.dtString = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
      this.$emit('changeDate', date)
      this.menu = false
    },

    now () {
      this.dtString = moment().format('DD/MM/YYYY')
      this.date = moment().format('YYYY-MM-DD')
      this.menu = false
    }
  }
}
</script>

<style>

</style>